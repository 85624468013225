import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import pic from '../images/services.jpg';

class SevicesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isArticleVisible: true,
      timeout: false,
      articleTimeout: false,
      article: 'services'
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.timerID1 = setTimeout(() => this.timeout(), 325);
    this.timerID2 = setTimeout(() => this.articleTimeout(), 350);
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  timeout() {
    this.setState({
      timeout: !this.state.timeout
    });
  }

  articleTimeout() {
    this.setState({
      articleTimeout: !this.state.articleTimeout
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <div id="wrapper" className="reduceSize">
            <article
              id="services"
              className={`${'active'} ${
                this.props.articleTimeout ? 'timeout' : ''
              }`}
            >
              <h2 className="major">Services</h2>
              <span className="image main">
                <img src={pic} alt="services" />
              </span>
              <div>
                <h3>Software and App Development</h3>
                <p>
                  Our years of accumulated knowledge is your gain. We know how
                  to build software the right way: architecture built to scale,
                  code that tests itself, automated builds and deployments.
                </p>
                <p>
                  We ascribe to the well-known "Agile Movement" that advocates
                  flexibility and delivering concrete results rapidly and
                  incrementally. This means we'll be able to show you results
                  quickly and when either you change your mind about something
                  (or the market does for you) we can adapt easily. That means
                  we'll always be working with your current needs, not what you
                  thought you needed six months ago.
                </p>
              </div>
              <div>
                <h3>Website Design and Development</h3>
                <p>
                  As digital technology and the software that runs it has become
                  increasingly complex and sophisticated, positive
                  human-computer interaction has become critically important.
                  Fundamental to the success of any technological product are
                  these simple, human reactions: does this product provide value
                  to me? Is it easy to use? Is it enjoyable to use?
                </p>
                <p>
                  That’s why we take a human-centered design approach in
                  everything we do. We understand that human behavior is what
                  drives software use and that poor user experience leads to
                  high bounce rates, sagging sales, and anemic growth.
                </p>
                <p>
                  We embrace a user experience design methodology. We dig deep
                  into the consumer psyche to uncover key insights and behavior
                  patterns that enable us to develop custom software that is
                  remarkably intuitive, even fun, to use.
                </p>
                <p>
                  And the benefits of good user experience design doesn't stop
                  there. The right application and a positive UX builds brand
                  value, increases customer loyalty, creates sustainable growth,
                  and improves profitability.
                </p>
              </div>
              <div>
                <h3>Managed Services</h3>
                <p>
                  Do you depend on technology to maximize the efficiency of your
                  business? Has that technology ever let you down? If you are
                  like other businesses, you have your fair share of horror
                  stories. Whether you have tales of downtime brought on by
                  computer or network problems, we're here to produce results
                  and reduce costly downtime with dependable service.
                </p>
              </div>
              <div>
                <h3>Custom Integration Strategies</h3>
                <p>
                  Fitting specific business processes into off the shelf
                  software can be like shoving a square peg into a round hole.
                  We helps move people off of shared spreadsheets to engaging
                  web applications designed to meet your needs while recognizing
                  that businesses evolve and change.
                </p>
                <p>
                  Our scalable web applications are built for the user and
                  encourage adoption. If necessary, we provide robust and highly
                  available systems integration for custom processes and working
                  database relationships, enabling your business to work more
                  efficiently.
                </p>
              </div>
            </article>

            <Footer />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    );
  }
}

export default SevicesPage;
